import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "~/components/common/Layout"
import pages, {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import { Heading, Text } from "~/components/common/Typography"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import { rgba } from "polished"
import Carousel from "~/components/page/Carousel"
import { Slide } from "pure-react-carousel"
import Img from "gatsby-image"
import Copyright from "~/components/common/Copyright"
import useDimensions from "react-use-dimensions"
import { Accordion } from "~/components/common/Accordion"
import ErrorBoundary from "~/components/common/ErrorBoundary"

const mqMobileTablet = mq({
  display: ["block", "block", "none"],
})
const mqDesktop = mq({
  display: ["none", "none", "block"],
})

const Page = ({ myPage = 2, mySubpage = 2 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "3.3HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      picture: file(name: { eq: "3.3BOTTOMOFPAGE" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic1: file(name: { eq: "3.3a-ADIDAS-arms" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic2: file(name: { eq: "3.3b-ADIDAS-thumbsup" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic3: file(name: { eq: "3.3c-ADIDAS-nowhistle" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slider1: file(name: { eq: "3.3-ADIDAS-Slide1" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slider2: file(name: { eq: "3.3-ADIDAS-Slide2" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slider3: file(name: { eq: "3.3-ADIDAS-Slide3" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slider4: file(name: { eq: "3.3-ADIDAS-Slide4" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      slider5: file(name: { eq: "3.3-ADIDAS-Slide5" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const isOpenInitialState = {}
  pages.forEach((_, index) => (isOpenInitialState[index] = false))
  const [isOpen, setIsOpen] = useState(isOpenInitialState)

  const [ref, { x, height, width }] = useDimensions()

  const {
    hero,
    picture,
    pictureMobile,
    graphic1,
    graphic2,
    graphic3,
    slider1,
    slider2,
    slider3,
    slider4,
    slider5,
  } = data

  const sliderData = [
    {
      text: `Why might a player be late? 
      It could be a variety of reasons: a long commute to practice, other responsibilities (such as a job or family obligation), unreliable transportation, schoolwork, etc.`,
      image: slider1,
    },
    {
      text: `Should these reasons result in removal from the program?
No, these are circumstances that are out of the player’s control and are important aspects of their daily life.
In order for you to respond to players appropriately, you must seek to understand their context so you can support them in the ways they need.`,
      image: slider2,
    },
    {
      text: `But that doesn’t mean being late is OK. You will still want to work with the player to help her get to practice on time.
The conversations you have and the actions you take will differ based on an understanding of the context.`,
      image: slider3,
    },
    {
      text: `Are players sometimes late because they lost track of time, were hanging out with friends, or simply forgot?
You must treat these situations differently. The way you respond here may be more direct and have a more immediate consequence.`,
      image: slider4,
    },
    {
      text: `You must acknowledge that fair is not always equal and that context will inform how you handle each situation. You as a coach must also accept that doing this means you will need to have honest and challenging conversations with your players as to why different players receive different treatment for similar actions.
This is another reason why the relationships you build are so important. Conversations are more manageable when athletes know and trust you.`,
      image: slider5,
      ref: ref,
    },
  ]

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="In building a safe and supportive culture, you as a coach must define what safety looks and sounds like."
        image={hero}
        bg={"white"}
      />

      <Infobox uppercase as={"section"}>
        We’ve already talked about how to build a structure of safety through
        strict behavior codes, but now let’s explore non-negotiables. Why are
        they so important? And how you can reinforce them in a way that keeps
        your girls feeling safe and comfortable to grow and build their skills?
      </Infobox>

      <InfoboxDetailed
        as={"section"}
        headerMaxWidth={"desktop"}
        headerText={"The Three Non-Negotiables"}
        py={6}
      >
        <Flex
          maxWidth={"desktop"}
          mx={"auto"}
          justifyContent={"center"}
          alignItems={"start"}
          my={6}
          css={css`
            flex-wrap: wrap;
          `}
        >
          <Box width={["100%", "33%"]}>
            <Text
              my={0}
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              No physical violence
            </Text>
            <GraphicWithText
              image={graphic1}
              width={["100%"]}
              px={6}
              mq={mq({
                width: ["50%", "100%"],
              })}
            >
              <Text mt={0}>
                <span
                  css={css`
                    font-family: "AdineuePROBoldWeb";
                  `}
                >
                  Practice is a physically safe space:{" "}
                </span>
                <br />
                Athletes do not hit, harm, or seek to injure anyone else while
                in this space. When you explain this to your girls, give them
                examples of physical violence so that everyone understands what
                it looks like and how to avoid it.
              </Text>
            </GraphicWithText>
          </Box>

          <Box width={["100%", "33%"]}>
            <Text
              my={0}
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              No unsupervised activity
            </Text>
            <GraphicWithText
              image={graphic3}
              width={["100%"]}
              px={6}
              mq={mq({
                width: ["50%", "100%"],
              })}
            >
              <Text mt={0}>
                <span
                  css={css`
                    font-family: "AdineuePROBoldWeb";
                  `}
                >
                  Players must remain with the coach or an adult while at
                  practice:{" "}
                </span>
                <br />
                You will ensure athletes are following the non-negotiables and
                stay out of physical danger.
              </Text>
            </GraphicWithText>
          </Box>
          <Box width={["100%", "33%"]}>
            <Text
              my={0}
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              No put-downs
            </Text>
            <GraphicWithText
              image={graphic2}
              width={["100%"]}
              px={6}
              mq={mq({
                width: ["50%", "100%"],
              })}
            >
              <Text mt={0}>
                <span
                  css={css`
                    font-family: "AdineuePROBoldWeb";
                  `}
                >
                  Practice is an emotionally safe space:{" "}
                </span>
                <br />
                Athletes do not call people names, make fun of others, or do
                things that will make others feel bad. Creating emotional safety
                takes commitment from the whole team.
              </Text>
            </GraphicWithText>
          </Box>
        </Flex>
        <Box px={3} maxWidth={"mediumContent"} mx={"auto"}>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            Enforcing a strict behavior code with non-negotiables is not easy,
            but it is of the utmost importance. By investing in this early on,
            you can create an environment that supports the types of
            relationships and learning that girls will carry with them through
            their time on the team and afterwards. It also allows you to focus
            on practice and not on behavior management.
          </Text>
        </Box>
      </InfoboxDetailed>
      <InfoboxDetailed
        as={"section"}
        bg={"seafoam"}
        headerText={"Here are three tips to help enforce the SBC:"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box
          bg={"white-panel"}
          mt={[5, 6]}
          px={[5, 5, 6]}
          py={[3, 6]}
          pb={[4, 7]}
        >
          <Box my={5}>
            <Accordion
              boldHeader
              heading={"Consistency"}
              onToggle={() =>
                setIsOpen(s => {
                  return Object.assign({}, s, { [0]: !s[0] })
                })
              }
              isOpen={isOpen[0]}
            >
              <Text>
                Be consistent with the implementation of the code. By
                consistently talking about the non-negotiables, referring back
                to the code, and reminding players of it, you will be able to
                demonstrate how serious and important the code is throughout the
                season. Girls will also be able to understand what to expect
                from you and know which behaviors will be met with positive
                praise and which ones will break the code. With this foundation,
                you’ll be able to build the skills you need to work within your
                established regulations.
              </Text>
            </Accordion>
          </Box>
          <Box my={5}>
            <Accordion
              boldHeader
              heading={"Clarity"}
              onToggle={() =>
                setIsOpen(s => {
                  return Object.assign({}, s, { [1]: !s[1] })
                })
              }
              isOpen={isOpen[1]}
            >
              <Text>
                It is crucial for girls to understand what the behaviors are for
                each of the non-negotiables. You can encourage this by focusing
                on things you can see or hear and clarifying what is allowed
                during practice and what is not. There should be no confusion
                around what one can or cannot do. The goal is to set players up
                to thrive by giving them all of the tools they need.
              </Text>
            </Accordion>
          </Box>
          <Box my={5}>
            <Accordion
              boldHeader
              heading={"Simplicity"}
              onToggle={() =>
                setIsOpen(s => {
                  return Object.assign({}, s, { [2]: !s[2] })
                })
              }
              isOpen={isOpen[2]}
            >
              <Text>
                Non-negotiables should be short and easy to remember, both for
                players and for you and your fellow coaches. Simplicity also
                helps us apply them consistently for players over time. Keeping
                the list short sets a bottom line that all players can refer
                back to. No matter who they are, no one should break the
                non-negotiable rules during practice.
              </Text>
            </Accordion>
          </Box>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        as={"section"}
        headerText={
          "While non-negotiables are extremely important, they are just one part of cultivating a safe and supportive culture. "
        }
        headerMaxWidth={"desktop"}
        pb={0}
      >
        <Box maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            There are other expectations you have for your team, such as showing
            up on time or watching your language. These things are important but
            separate from the non-negotiables.
          </Text>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed as={"section"} contentMaxWidth={"desktop"} pb={4} pt={4}>
        <Box maxWidth={"mediumContent"} mx={"auto"}>
          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            Remember, your goal as coaches is to use the power of sports to give
            girls skills that will uplift them through adulthood.
          </Text>
          <Text>
            In order to learn these skills, you need to invoke them at practice.
          </Text>
          <Text>
            You as a coach should be familiar with your team or organization’s
            policies and follow programmatic guidelines around behavior
            management when applicable. For example, issuing warnings, meeting
            with parents, suspensions, and more.
          </Text>
        </Box>
      </InfoboxDetailed>

      <ErrorBoundary>
        <Box
          as="section"
          flexDirection="column"
          justifyContent="start"
          pt="6"
          px="6"
          pb="6"
          bg="seafoam"
        >
          <Box textAlign="center" maxWidth={"desktop"} mx={"auto"}>
            <Heading
              fontSize={["24px", "32px"]}
              lineHeight={["29px", "48px"]}
              mb="5"
            >
              There’s a reason why we emphasize the distinction between
              non-negotiables and other expectations. You should always think
              about why these situations occur.
            </Heading>
          </Box>
          <Box
            maxWidth="desktop"
            mx="auto"
            bg={rgba("white", 0.8)}
            mt="5"
            px={[5, 5, 6]}
            py={[6]}
          >
            <Box mx="auto" bg="white" px={[3, 5]} py={[4, 5]}>
              <Carousel>
                {sliderData.map((slide, index) => (
                  <Slide
                    key={index}
                    index={index}
                    style={{ height: `${height + 10}px` }}
                  >
                    <Flex
                      flexDirection={["column"]}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={["80%", "80%", "70%"]}
                      mt={[4, 5]}
                      mx={"auto"}
                      height={slide.ref ? "unset" : "100%"}
                      ref={slide.ref}
                    >
                      <Box width={"100%"}>
                        <Img
                          fluid={slide.image.childImageSharp.fluid}
                          loading={"eager"}
                        />
                      </Box>
                      <Box width={"100%"}>
                        <Text
                          textAlign={"center"}
                          px={6}
                          fontSize={["16px", "16px", "18px"]}
                          lineHeight={["24px", "24px", "36px"]}
                        >
                          {slide.text}
                        </Text>
                      </Box>
                    </Flex>
                  </Slide>
                ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </ErrorBoundary>

      <InfoboxDetailed
        as={"section"}
        contentMaxWidth={"desktop"}
        headerText={"So what’s next?"}
      >
        <Flex
          flexDirection={["column-reverse", "column-reverse", "row"]}
          maxWidth={"desktop"}
          mx={"auto"}
          mt={6}
        >
          <Box
            width={["100%", "100%", "50%"]}
            textAlign={["center", "left"]}
            pl={[0, 0, 7]}
            pr={[0, 0, 6]}
            fontSize={["16px", "18px"]}
            lineHeight={["24px", "36px"]}
            pt={[5, 5, 0]}
          >
            <Text mt={[0]}>
              By now, you should understand the role of non-negotiables and the
              importance of acknowledging context when determining responses to
              behavioral challenges outside the non-negotiables.
            </Text>
            <Text>
              Everything you do as a coach is with the goal of skill-building.
              Enforcing a strict behavior code and non-negotiables should be no
              different. When something happens, and the code or non-negotiables
              are broken, take the following principles into consideration when
              thinking about next steps.
            </Text>
            <Text
              mb={3}
              uppercase
              fontSize={["20px", "24px"]}
              lineHeight={["24px", "27px"]}
              fontFamily={"AdineuePROBlackWeb"}
            >
              Team and players’ safety
            </Text>
            <Text mt={0}>
              This is the most important thing to consider. Always make sure the
              players are safe.
            </Text>
            <Text
              mb={3}
              uppercase
              fontSize={["20px", "24px"]}
              lineHeight={["24px", "27px"]}
              fontFamily={"AdineuePROBlackWeb"}
            >
              Internal organizational policies
            </Text>
            <Text mt={0}>
              Consider the policies of the team and program. What is the
              established procedure for these types of situations, and is there
              anyone you must consult?
            </Text>
            <Text
              mb={3}
              uppercase
              fontSize={["20px", "24px"]}
              lineHeight={["24px", "27px"]}
              fontFamily={"AdineuePROBlackWeb"}
            >
              Skill-building
            </Text>
            <Text mt={0}>
              What actions should you take as a coach to help a player build the
              skills she needs to be successful? What does her behavior say
              about the areas she needs to work on? What can you do to push her
              in the right direction?
            </Text>
          </Box>
          <Box width={["100%", "100%", "50%"]} px={5} mx={"auto"}>
            <Img
              css={mqDesktop}
              fluid={{
                ...picture.childImageSharp.fluid,
                aspectRation: 1054 / 800,
              }}
              loading={"eager"}
            ></Img>
            <Img
              css={mqMobileTablet}
              fluid={{
                ...picture.childImageSharp.fluid,
                aspectRatio: 800 / 750,
              }}
              loading={"eager"}
            ></Img>
          </Box>
        </Flex>
      </InfoboxDetailed>
      <Copyright />
    </Layout>
  )
}

export default withProgress(Page, 2, 2)
